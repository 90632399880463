import { graphql } from 'gatsby';
import React from 'react';
import FlexibleContent from '../components/FlexibleContent';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import InsightsFooter from '../components/Static/Insights/InsightsFooter';
import NewsListingBody from '../components/Static/NewsListing/NewsListingBody';

const NewsListing = ({ data, location, pageContext }) => {
  const {
    seo,
    hero,
    title,
    flexibleContent,
    content,
    date,
    insightsFlexibleContent,
    link,
    author,
    hreflang
  } = pageContext;
  return (
    <Layout>
      <SEO pathname={location.pathname} seo={seo} hreflang={hreflang} />
      <NewsListingBody
        breadcrumbs={seo.breadcrumbs}
        content={content}
        title={title}
        latestNews={data.latestPosts.nodes}
        date={date}
        url={link}
        author={author}
      />
      <FlexibleContent prefix='post' components={flexibleContent} />
      <InsightsFooter content={insightsFlexibleContent} />
    </Layout>
  );
};

export default NewsListing;
export const query = graphql`
  query {
    latestPosts: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { categories: { nodes: { elemMatch: { name: { eq: "News" } } } } }
      limit: 2
    ) {
      nodes {
        title
        link
        date
      }
    }
  }
`;
