import { format } from 'date-fns';
import { Link } from 'gatsby';
import React, { FC, useRef } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import styled from 'styled-components';
import Facebook from '../../../assets/svg/facebook-blue.svg';
import Twitter from '../../../assets/svg/twitter-blue.svg';
import variables from '../../../helpers/variables';
import useBlueMenuTrigger from '../../../hooks/useBlueMenuTrigger';
import Author from '../../Shared/Author';
import Block from '../../Shared/Block';
import Breadcrumb from '../../Shared/Breadcrumb';
import ButtonPink from '../../Shared/ButtonPink';
import CategoryHeader from '../../Shared/CategoryHeader';
import ContactPhoneNumber from '../../Shared/ContactPhoneNumber';
import Divider from '../../Shared/Divider';
import H2 from '../../Shared/H2';
import H3 from '../../Shared/H3';
import P from '../../Shared/P';
import Row from '../../Shared/Row';
import RowHero from '../../Shared/RowHero';
import Section from '../../Shared/Section';
import SectionHero from '../../Shared/SectionHero';
import Wysiwyg from '../../Shared/Wysiwyg';

interface Props {
  breadcrumbs: any[];
  content: string;
  title: string;
  latestNews: any[];
  date: string;
  url: string;
  author: any;
}

const NewsListingBody: FC<Props> = ({
  breadcrumbs,
  content,
  title,
  latestNews,
  date,
  url,
  author
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const uniqueId = useBlueMenuTrigger(ref);

  return (
    <NewsListingBodySection id={uniqueId} ref={ref}>
      <RowHero>
        <Block>
          <Header>
            <HeaderBreadcrumb>
              <Breadcrumb breadcrumbs={breadcrumbs} isColorInverted />
            </HeaderBreadcrumb>
            <HeaderContact>
              <ContactPhoneNumber color={variables.css.color.black} />
              <ButtonPink
                onClick={() => {
                  document
                    .getElementById('animated-form')
                    .scrollIntoView({ behavior: 'smooth' });
                }}
              >
                Get in touch
              </ButtonPink>
            </HeaderContact>
          </Header>
        </Block>
        <Block lgWidth={2 / 3} xlWidth={7 / 9}>
          <Row>
            <Block>
              <PostDate isColorInverted={true}>
                {date && format(new Date(date), 'MMMM dd, yyyy')}
              </PostDate>
            </Block>
            <Block>
              <PostTitle isColorInverted={true}>{title}</PostTitle>
            </Block>
            <Block>
              <Author author={author} isLarge={true}/>
              <ShareThisArticleText isColorInverted={true}>
                Share this Article:
                <PaddedDiv>
                  <FacebookShareButton url={`www.mediaworks.co.uk${url}`}>
                    <Facebook />
                  </FacebookShareButton>
                </PaddedDiv>
                <PaddedDiv>
                  <TwitterShareButton url={`www.mediaworks.co.uk${url}`}>
                    <Twitter />
                  </TwitterShareButton>
                </PaddedDiv>
              </ShareThisArticleText>
            </Block>
          </Row>
          <Row>
            <Block>
              <Wysiwyg
                isColorBlack={true}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </Block>
          </Row>
        </Block>
        <StickyBlock lgWidth={1 / 3} xlWidth={2 / 9}>
          {/* <HiddenP isColorInverted={true}>
            <a href='tel:+443301086522'>+44 (0) 330 108 6522</a>
          </HiddenP> */}
          <CategoryHeader>Latest News</CategoryHeader>
          <Link to={latestNews[0].link}>
            <LatestNewsDate isColorInverted={true}>
              {latestNews[0].date &&
                format(new Date(latestNews[0].date), 'MMMM dd, yyyy')}
            </LatestNewsDate>
            <LatestNewsTitle isColorInverted={true}>
              {latestNews[0].title}
            </LatestNewsTitle>
          </Link>
          <PaddedDivider />
          <Link to={latestNews[1].link}>
            <LatestNewsDate isColorInverted={true}>
              {latestNews[1].date &&
                format(new Date(latestNews[1].date), 'MMMM dd, yyyy')}
            </LatestNewsDate>
            <LatestNewsTitle isColorInverted={true}>
              {latestNews[1].title}
            </LatestNewsTitle>
          </Link>
          <PaddedDivider />
        </StickyBlock>
      </RowHero>
    </NewsListingBodySection>
  );
};

const Header = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
`;

const HeaderBreadcrumb = styled.div`
  flex: 1 1 0%;
`;

const HeaderContact = styled.div`
  align-items: center;
  display: none;
  flex: none;
  margin-left: 4rem;

  ${variables.breakpoints.lg} {
    display: flex;
  }
`;

const NewsListingBodySection = styled(SectionHero)`
  background-color: ${variables.css.color.white};
`;

const PaddedDiv = styled.div`
  padding-left: 0.25rem;
  padding-right: 0.25rem;
`;

const PostTitle = styled(H2)`
  ${variables.breakpoints.lg} {
    font-size: 3rem;
    margin-bottom: 1.625rem;
  }
`;

const StickyBlock = styled(Block)`
  ${variables.breakpoints.lg} {
    position: sticky;
    height: max-content;
    top: 4rem;
  }
`;

const HiddenP = styled(P)`
  font-size: 0.9rem;
  display: none;
  ${variables.breakpoints.lg} {
    display: block !important;
  }
`;

const WhiteSection = styled(Section)`
  background-color: ${variables.css.color.white};
`;

const PostDate = styled(P)`
  text-transform: uppercase;
`;
const LatestNewsDate = styled(P)`
  line-height: 1.75rem;
  font-size: 0.875rem;
  font-weight: ${variables.css.fontWeight.light};
  text-transform: uppercase;
`;

const LatestNewsTitle = styled(H3)`
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 1.375rem;
`;

const PaddedDivider = styled(Divider)`
  border: none;
  border-bottom: 1px solid ${variables.css.color.grey};
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const ShareThisArticleText = styled(P)`
  text-transform: uppercase;
  display: flex;
`;

export default NewsListingBody;
