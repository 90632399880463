import styled from 'styled-components';
import variables from '../../helpers/variables';

const CategoryHeader = styled.p`
  background-color: ${variables.css.color.pink};
  color: ${variables.css.color.white};
  font-weight: ${variables.css.fontWeight.black};
  text-transform: uppercase;
  font-family: ${variables.css.fontFamily};
  width: max-content;
  padding: 0.25rem;
  margin-bottom: 2rem;
`;

export default CategoryHeader;
