import React, { FC, Fragment } from 'react';
import AnimatedForm from '../../Flexible/AnimatedForm';
import ContentSlider from '../../Flexible/ContentSlider';
import Ctas from '../../Flexible/Ctas';

type Props = {
  content: any;
};

const InsightsFooter: FC<Props> = ({ content }) => {
  return (
    <Fragment>
      <Ctas
        ctas={content.ctas}
        columns={content.columns}
        isColorInverted={content.invertColours}
      />
      <ContentSlider slides={content.contentSlide} />
      <AnimatedForm
        splashTitle={content.title}
        splashSubtitle={content.subtitle}
        title={content.title}
        introduction={content.introduction}
        formTitle={content.formTitle}
        formTerms={content.formTerms}
        image={content.image}
        imageMobile={content.imageMobile}
      />
    </Fragment>
  );
};

export default InsightsFooter;
