import React, { FC } from 'react';
import styled from 'styled-components';
import variables from '../../helpers/variables';
import P from './P';

type Props = {
  author: any;
  isLarge?: boolean;
};

const Author: FC<Props> = ({
  author,
  isLarge
}) => {

  if (!author){
    return (<div></div>);
  }
  
  const { profileName, jobDescription, profilePhoto } = author.node.profileDetails;

  if (!profileName || !profilePhoto){
    return (<div></div>);
  }

  return (
    <Container style={{fontSize: (isLarge ? '1.25em' : '1em')}}>
      <ProfileImageContainer>
        <Image 
          src={profilePhoto.sourceUrl} 
          alt={profileName}
          style={{objectFit: 'cover'}}
        />
      </ProfileImageContainer>
      <div>
        <AuthorName>{profileName}</AuthorName>
        <JobTitle>{jobDescription}</JobTitle>
      </div>
    </Container>
  );
};

export default Author;

const Container = styled.div`
  display: flex;
  margin-bottom: 1.125em;
`;

const ProfileImageContainer = styled.div`
  position: relative;
  height: 3em;
  width: 3em;
  border-radius: 3em;
  overflow: hidden;
  margin-right: 0.75em;
`;

const AuthorName = styled(P)`
  font-weight: bold;
  margin-bottom: 0;
  font-size: 1em;
  color: ${variables.css.color.grey};
`;

const JobTitle = styled(P)`
  margin-bottom: 0;
  font-size: 1em;
  color: ${variables.css.color.grey};
`;

const Image = styled.img`
  height: 100%;
  width: 100%;
`